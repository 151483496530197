import React, {
  Suspense,
  lazy,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import loader from "./assets/img/loader/loading.svg";
import "./assets/css/styles.css";
import "sweetalert2/src/sweetalert2.scss";
import AuthContext from "./helpers/AuthContext";
import { authCheck, axiosRemoveToken, axiosSetToken } from "./helpers/api";
import { JWT_TOKEN } from "./helpers/config";
import {SocketContext, socket} from './helpers/SocketContext';

const AuthScreen = lazy(() => import("./screens/AuthScreen"));
const HomeScreen = lazy(() => import("./screens/HomeScreen"));
const RealTimeScreen = lazy(() => import("./screens/RealTimeScreen"));
const Error404Screen = lazy(() => import("./screens/Error404Screen"));
const LogoutScreen = lazy(() => import("./screens/LogoutScreen"));

// MANAGE
const ManageGuildScreen = lazy(() => import("./screens/Manage/GuildScreen"));
const ManageUserScreen = lazy(() => import("./screens/Manage/UserScreen"));
// TICKETS
const TicketsReportScreen = lazy(() => import("./screens/Tickets/ReportScreen"));
const TicketsReviewScreen = lazy(() => import("./screens/Tickets/ReviewScreen"));

// MARKETING
const RemiseScreen = lazy(() => import("./screens/Marketing/RemiseScreen"));
const InvoiceScreen = lazy(() => import("./screens/Marketing/InvoiceScreen"));
const GuildAdScreen = lazy(() => import("./screens/Marketing/GuildAdScreen"));

// SEARCH
const GuildSearchScreen = lazy(() => import("./screens/Search/GuildScreen"));
const UserSearchScreen = lazy(() => import("./screens/Search/UserScreen"));

// EDITS
const GuildEditScreen = lazy(() => import("./screens/Edits/GuildScreen"));
const UserEditScreen = lazy(() => import("./screens/Edits/UserScreen"));

// LISTS
const ReportListScreen = lazy(() => import("./screens/Lists/ReportScreen"));
const ReviewListScreen = lazy(() => import("./screens/Lists/ReviewScreen"));
const SanctionListScreen = lazy(() => import("./screens/Lists/SanctionScreen"));
const UserSanctionListScreen = lazy(() => import("./screens/Lists/SanctionUserScreen"));
const VoteListScreen = lazy(() => import("./screens/Lists/VoteScreen"));
const BoostListScreen = lazy(() => import("./screens/Lists/BoostScreen"));


const LoggedInRoute = ({ ...rest }) => {
  const auth = useContext(AuthContext);
  const referrer = document.location.href.replace(document.location.origin, "");
  return !!auth.authToken ? (
    <Route {...rest} />
  ) : (
    <Redirect to="/auth" from={referrer} push={false} />
  );
};

export default function RoutesMotor() {
  const [authState, setAuthState] = useState({
    authToken: null,
    authUser: null,
  });

  const auth = (token, user) => {
    setAuthState({ authToken: token, authUser: user });
  };

  const logout = () => {
    window.localStorage.removeItem(JWT_TOKEN);
    axiosRemoveToken();
    setAuthState({ authToken: null, authUser: null });
  };

  const processToken = useCallback((token) => {
    axiosSetToken(token);
    return authCheck()
      .then((checkRes) => {
        auth(token, checkRes.data.user);
        window.localStorage.setItem(JWT_TOKEN, token);
      })
      .catch((e) => {
        console.error(e);
        window.localStorage.removeItem(JWT_TOKEN);
      });
  }, []);

  useEffect(() => {
    const token = window.localStorage.getItem(JWT_TOKEN);
    if (token) {
      processToken(token);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ ...authState, auth, logout }}>
      <SocketContext.Provider value={socket}>
        <Router>
          <Suspense
            fallback={
              <div className="authRow">
                <div className="authBox">
                  <div className="authContent">
                    <img src={loader} alt="Loading in progress" />
                    <p>{"Chargement en cours... 🤗"}</p>
                  </div>
                </div>
              </div>
            }
          >
            <Switch>
              <LoggedInRoute exact path="/" component={HomeScreen} />
              <LoggedInRoute exact path="/realtime" component={RealTimeScreen} />
              <LoggedInRoute exact path="/manage/guilds" component={ManageGuildScreen} />
              <LoggedInRoute exact path="/manage/users" component={ManageUserScreen} />
              <LoggedInRoute exact path="/marketing/remise" component={RemiseScreen} />
              <LoggedInRoute exact path="/marketing/invoice" component={InvoiceScreen} />
              <LoggedInRoute exact path="/marketing/ad" component={GuildAdScreen} />
              <LoggedInRoute exact path="/tickets/reports" component={TicketsReportScreen} />
              <LoggedInRoute exact path="/tickets/reviews" component={TicketsReviewScreen} />
              <LoggedInRoute exact path="/search/guilds" component={GuildSearchScreen} />
              <LoggedInRoute exact path="/search/users" component={UserSearchScreen} />
              <LoggedInRoute exact path="/list/reports" component={ReportListScreen} />
              <LoggedInRoute exact path="/list/reviews" component={ReviewListScreen} />
              <LoggedInRoute exact path="/list/sanctions" component={SanctionListScreen} />
              <LoggedInRoute exact path="/list/sanctions/users" component={UserSanctionListScreen} />
              <LoggedInRoute exact path="/list/votes" component={VoteListScreen} />
              <LoggedInRoute exact path="/list/boosts" component={BoostListScreen} />
              <LoggedInRoute exact path="/guild/:guild_id" component={GuildEditScreen} />
              <LoggedInRoute exact path="/user/:user_id" component={UserEditScreen} />
              <Route path="/auth" component={AuthScreen} />
              <Route exact path="/logout" component={LogoutScreen} />
              <Route component={Error404Screen} />
            </Switch>
          </Suspense>
        </Router>
        </SocketContext.Provider>
    </AuthContext.Provider>
  );
}
