import axios from 'axios';
import { API_URL } from './config'

let axiosAuthInterceptor = null;

export const axiosRemoveToken = () => {
    if (axiosAuthInterceptor !== null) {
        axios.interceptors.request.eject(axiosAuthInterceptor)
        axiosAuthInterceptor = null
    }
}

export const axiosSetToken = (token) => {
    if (axiosAuthInterceptor === null) {
        axiosAuthInterceptor = axios.interceptors.request.use((config) => {
            config.headers['Authorization'] = `Bearer ${token}`
            return config
        })
    }
}

export const login = async () => {
    return await axios.get(`${API_URL}/discord/redirect`)
}

export const getAccessToken = async (code) => {
    return await axios.get(`${API_URL}/discord/callback?code=${code}`)
}

export const authCheck = async () => {
    return await axios.get(`${API_URL}/auth/user`)
}

export const getDetailsNavbar = async () => {
    return await axios.get(`${API_URL}/dashboard/details`)
}

export const getLastActivity = async () => {
    return await axios.get(`${API_URL}/dashboard/last`)
}

export const getStats = async () => {
    return await axios.get(`${API_URL}/dashboard/stats`)
}

export const getLogs = async () => {
    return await axios.get(`${API_URL}/dashboard/logs`)
}

export const getReports = async () => {
    return await axios.get(`${API_URL}/dashboard/reports`)
}

export const putReport = async (credentials) => {
    return await axios.put(`${API_URL}/dashboard/report/${credentials.report_id}`, credentials)
}

export const putReview = async (credentials) => {
    return await axios.put(`${API_URL}/dashboard/review/${credentials.review_id}`, credentials)
}

export const getReviews = async () => {
    return await axios.get(`${API_URL}/dashboard/reviews`)
}

export const getGuild = async (credentials) => {
    return await axios.get(`${API_URL}/dashboard/guild/${credentials}`)
}

export const getUser = async (credentials) => {
    return await axios.get(`${API_URL}/dashboard/user/${credentials}`)
}

export const putUser = async (credentials) => {
    return await axios.put(`${API_URL}/dashboard/user/${credentials.user_id}`, credentials)
}

export const putGuild = async (credentials) => {
    return await axios.put(`${API_URL}/dashboard/guild/${credentials.guild_id}`, credentials)
}

export const putGuildProgram = async (credentials) => {
    return await axios.put(`${API_URL}/dashboard/guild/${credentials.guild_id}/programs`, credentials)
}

export const  newGuildSanction = async (credentials) => {
    return await axios.post(`${API_URL}/dashboard/guild/${credentials.guild_id}/sanctions`, credentials)
}

export const  newUserSanction = async (credentials) => {
    return await axios.post(`${API_URL}/dashboard/user/${credentials.user_id}/sanctions`, credentials)
}

export const getReportsHistory = async (credentials) => {
    return await axios.get(`${API_URL}/dashboard/report/${credentials}`)
}

export const getSanctions = async (credentials) => {
    return await axios.get(`${API_URL}/dashboard/sanction/${credentials}`)
}

export const putSanction = async (credentials) => {
    return await axios.put(`${API_URL}/dashboard/sanction/${credentials}`)
}

export const getUsersSanctions = async (credentials) => {
    return await axios.get(`${API_URL}/dashboard/user/sanction/${credentials}`)
}

export const deleteSanction = async (credentials) => {
    return await axios.delete(`${API_URL}/dashboard/sanction/${credentials}`)
}

export const putUserSanction = async (credentials) => {
    return await axios.put(`${API_URL}/dashboard/user/sanction/${credentials}`)
}

export const deleteUserSanction = async (credentials) => {
    return await axios.delete(`${API_URL}/dashboard/user/sanction/${credentials}`)
}

export const getReviewsHistory = async (credentials) => {
    return await axios.get(`${API_URL}/dashboard/review/${credentials}`)
}

export const getVotesHistory = async (credentials) => {
    return await axios.get(`${API_URL}/dashboard/vote/${credentials}`)
}

export const getBoostHistory = async (credentials) => {
    return await axios.get(`${API_URL}/dashboard/boost/${credentials}`)
}

export const getCodePromoList = async () => {
    return await axios.get(`${API_URL}/dashboard/marketing/remises`)
}

export const putCodePromo = async (credentials) => {
    return await axios.put(`${API_URL}/dashboard/marketing/remise/${credentials.id}`, credentials)
}

export const deleteCodePromo = async (credentials) => {
    return await axios.delete(`${API_URL}/dashboard/marketing/remise/${credentials}`)
}

export const newCodePromo = async (credentials) => {
    return await axios.post(`${API_URL}/dashboard/marketing/remise`, credentials)
}

export const getInvoices = async () => {
    return await axios.get(`${API_URL}/dashboard/marketing/invoices`)
}

export const deleteInvoices = async (credentials) => {
    return await axios.delete(`${API_URL}/dashboard/marketing/invoice/${credentials.id}`)
}

export const getAds = async () => {
    return await axios.get(`${API_URL}/dashboard/marketing/ads`)
}

export const putAd = async (credentials) => {
    return await axios.put(`${API_URL}/dashboard/marketing/ad/${credentials.id}`, credentials)
}
export const newAd = async (credentials) => {
    return await axios.post(`${API_URL}/dashboard/marketing/ads`, credentials)
}